import React, { useEffect, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classNames from "classnames"
import Link from "../components/GatsbyLink"

const IconTick = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 8.62028L10.2695 11.0125C10.6842 11.3159 11.2613 11.2557 11.6044 10.8731L18 3.74243"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M19 10.5715C19 12.406 18.411 14.1945 17.3156 15.6857C16.2203 17.1769 14.6736 18.2959 12.893 18.8856C11.1123 19.4753 9.18696 19.506 7.38744 18.9735C5.58792 18.441 4.00459 17.3719 2.85982 15.9165C1.71505 14.461 1.06635 12.6923 1.00482 10.8587C0.943291 9.02519 1.47203 7.21888 2.51677 5.69352C3.56152 4.16815 5.06979 3.00035 6.82975 2.35413C8.58971 1.70792 10.513 1.61574 12.3294 2.09056"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
)

const AboutUs = ({ title, bgColor, children, list = [] }) => {
  const [activeItem, setActiveItem] = useState(list[0])
  const [hasItemHovered, setHasItemHovered] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      if (hasItemHovered) return

      setActiveItem(item => {
        const currentIdx = list.indexOf(item)
        const newIdx = list.length - 1 === currentIdx ? 0 : currentIdx + 1
        return list[newIdx]
      })
    }, 3000)

    return () => {
      clearInterval(timer)
    }
  }, [list, hasItemHovered])

  return (
    <div
      className="about-us"
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="container">
        <h3 className="about-us-title">{title}</h3>

        {children}

        <ul className="activity-event-list">
          {list.map(item => (
            <li key={item.id}>
              <div
                className={classNames({ active: item === activeItem })}
                onMouseEnter={() => {
                  setActiveItem(item)
                  setHasItemHovered(true)
                }}
                onMouseLeave={() => {
                  setHasItemHovered(false)
                }}
              >
                <IconTick />
                <div className="text">
                  {item.link ?
                    <Link to={item.link}>
                      <strong>{item.optionHeader}</strong>{" "}
                      <span>{item.optionContent}</span>
                    </Link> :
                    <div>
                      <strong>{item.optionHeader}</strong>{" "}
                      <span>{item.optionContent}</span>
                    </div>
                  }

                  {item?.image.localFile && (
                    <GatsbyImage
                      image={{
                        placeholder: bgColor,
                        ...getImage(item?.image.localFile),
                      }}
                      alt={item.optionHeader}
                      className="side-img"
                    />
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AboutUs
