import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"
import classNames from "classnames"
import Link from "./GatsbyLink"

const params = {
  pagination: false,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  noSwiping: false,
  spaceBetween: 0,
  autoplay: {
    delay: 10000,
    disableOnInteraction: false,
  },
}

const categoriesParams = {
  spaceBetween: 0,
  centerInsufficientSlides: true,
  centeredSlides: true,
  centeredSlidesBounds: true,
  slidesPerView: "auto",
  slideToClickedSlide: true,
  followFinger: true,
  breakpoints: {
    1200: {
      spaceBetween: 6,
      centeredSlides: false,
      centeredSlidesBounds: false,
    },
  },
}

const Slider = ({ sliders = [] }) => {
  const ref = useRef()
  const categoriesRef = useRef()

  const [sliderActiveIdx, setSliderActiveIdx] = useState(0)

  const slideTo = index => {
    ref.current.swiper.slideTo(index)
  }

  useEffect(() => {
    const swiper = ref.current?.swiper
    if (swiper) {
      swiper.on("slideChange", () => {
        const { activeIndex } = swiper
        setSliderActiveIdx(activeIndex)
      })
    }
  }, [ref.current])

  useEffect(() => {
    const swiper = ref.current?.swiper
    const categoriesSwiper = categoriesRef.current?.swiper
    if (swiper.controller && categoriesSwiper.controller) {
      swiper.controller.control = categoriesSwiper
    }
  }, [])

  return (
    <div className="home-slider">
      <Swiper {...params} ref={ref}>
        {sliders.map(slider => (
          <div key={slider.id}>
            <div className="hidden-xs">
              <GatsbyImage
                image={getImage(slider.image.localFile)}
                alt={slider.header}
              />
            </div>

            <div className="visible-xs">
              <GatsbyImage
                image={getImage(slider?.mobileImage.localFile)}
                alt={slider.header}
              />
            </div>

            <div className="container">
              <div className="detail">
                <h1 className="title">{slider.header}</h1>
                <p className="text">{slider.detail}</p>

                <div className="buttons">
                  {slider.buttons.map((button, ind) => (
                    <Link
                      key={ind}
                      to={button.link?.trim()}
                      className="btn btn-primary"
                      style={{
                        color: button.textColor,
                        backgroundColor: button.backgroundColor,
                      }}
                      onMouseEnter={e => {
                        e.target.style.color = button.textHoverColor
                        e.target.style.backgroundColor =
                          button.backgroundHoverColor
                      }}
                      onMouseLeave={e => {
                        e.target.style.color = button.textColor
                        e.target.style.backgroundColor = button.backgroundColor
                      }}
                    >
                      {button.text}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </Swiper>

      <div className="container with-gutter">
        <div className="categories">
          <Swiper {...categoriesParams} ref={categoriesRef}>
            {sliders.map((slider, idx) => (
              <div key={idx}>
                <button
                  key={idx}
                  type="button"
                  className={classNames("btn", {
                    active: idx === sliderActiveIdx,
                  })}
                  onClick={() => {
                    slideTo(idx)
                    setSliderActiveIdx(idx)
                  }}
                >
                  <span
                    className="btn-mark"
                    style={{
                      backgroundColor: slider.categoryColor,
                    }}
                  ></span>
                  {slider.categoryName}
                </button>
              </div>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Slider
