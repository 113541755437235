import React from "react"
import { graphql } from "gatsby"
import "swiper/css/swiper.css"

import MainLayout from "../layouts/MainLayout"
import Seo from "../components/Seo"
import Slider from "../components/Slider"
import CardsWithSwiper from "../components/CardsWithSwiper"
import AboutUs from "../components/AboutUs"
import "../i18n"
import useChangeLanguage from "../hooks/useChangeLanguage"

const paramsFirstRow = {
  slidesPerView: 3,
  spaceBetween: 32,
  grabCursor: true,
  loop: false,
  navigation: false,
  breakpoints: {
    1200: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
  },
}

const paramsSecondRow = {
  slidesPerView: 4,
  spaceBetween: 32,
  grabCursor: true,
  loop: false,
  navigation: false,
  breakpoints: {
    1200: {
      slidesPerView: 4,
      spaceBetween: 32,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 32,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 32,
    },
    320: {
      slidesPerView: 1,
      spaceBetween: 32,
    },
  },
}

const Home = ({ data, pageContext }) => {
  const { locale, transparentNavbar } = pageContext
  useChangeLanguage({ locale })
  return (
    <MainLayout transparentNavbar={transparentNavbar} locale={locale}>
      <Seo
        title={`${data.strapiHomePage.seo.title}`}
        description={`${data.strapiHomePage.seo.description}`}
      />
      <Slider sliders={data.strapiHomePage.sliders} />

      <CardsWithSwiper
        nodes={data.firstRow.nodes.sort((a, b) => {
          // Prioritize pinned posts
          if (a.pin && !b.pin) return -1;
          if (!a.pin && b.pin) return 1;

          // If both dates are null or both are pinned, sort based on createdAt
          if ((!a.date && !b.date) || (a.pin && b.pin)) return new Date(b.createdAt) - new Date(a.createdAt);

          // If only one date is null, place it at the end
          if (!a.date) return 1;
          if (!b.date) return -1;

          // If neither date is null and neither are pinned, sort based on date first, then createdAt
          const dateDiff = new Date(b.date) - new Date(a.date);
          if (dateDiff !== 0) return dateDiff;

          return new Date(b.createdAt) - new Date(a.createdAt);
        })}
        textTruncate={125}
        swiperParams={paramsFirstRow}
        rootClass="home home-first-row"
      />

      <CardsWithSwiper
        nodes={data.secondRow.nodes.sort((a, b) => {
          // Prioritize pinned posts
          if (a.pin && !b.pin) return -1;
          if (!a.pin && b.pin) return 1;

          // If both dates are null or both are pinned, sort based on createdAt
          if ((!a.date && !b.date) || (a.pin && b.pin)) return new Date(b.createdAt) - new Date(a.createdAt);

          // If only one date is null, place it at the end
          if (!a.date) return 1;
          if (!b.date) return -1;

          // If neither date is null and neither are pinned, sort based on date first, then createdAt
          const dateDiff = new Date(b.date) - new Date(a.date);
          if (dateDiff !== 0) return dateDiff;

          return new Date(b.createdAt) - new Date(a.createdAt);
        })}
        rootClass="home"
        swipeParams={paramsSecondRow}
      />

      <AboutUs
        bgColor={data.strapiHomePage.secondSectionBackgroundColor}
        title={data.strapiHomePage.secondSectionHeader}
        list={data.strapiHomePage.contents}
      >
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.strapiHomePage.secondSectionContent.data
                .secondSectionContent,
          }}
        ></div>
      </AboutUs>
    </MainLayout>
  )
}

export default Home

export const pageQuery = graphql`
  query ($id: String!, $firstBlogTag: String!, $secondBlogTag: String!) {
    strapiHomePage(id: { eq: $id }) {
      contents {
        id
        optionContent
        optionHeader
        link
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      secondSectionHeader
      secondSectionContent {
        data {
          secondSectionContent
        }
      }
      secondSectionBackgroundColor
      seo {
        description
        keywords
        title
      }
      sliders {
        id
        buttons {
          textColor
          textHoverColor
          backgroundColor
          backgroundHoverColor
          link
          text
        }
        categoryColor
        categoryName
        header
        detail
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        mobileImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
    firstRow: allStrapiBlogPost(
      filter: { tag: { id: { eq: $firstBlogTag } } }
      sort: { fields: createdAt, order: DESC }
      limit: 10
    ) {
      nodes {
        id
        slug
        header {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date
        pin
        tag {
          name
        }
      }
    }
    secondRow: allStrapiBlogPost(
      filter: { tag: { id: { eq: $secondBlogTag } } }
      sort: { fields: createdAt, order: DESC }
      limit: 10
    ) {
      nodes {
        id
        slug
        header {
          title
          description
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        date
        pin
        tag {
          name
        }
      }
    }
  }
`
